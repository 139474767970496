export const salesDeptOrder = ['社内販売（本部）', '北海道東北統括部', '首都圏統括部', '中部統括部', '近畿中四国統括部', '九州統括部', '広域営業部', '輸出営業部'];

export const salesBranchOrder = [
    '北海東北　北海道',
    '北海東北　仙台',
    '北海東北　盛岡',
    '首都圏　新潟',
    '首都圏　長野',
    '首都圏　東京',
    '首都圏　北関東',
    '首都圏　神奈川',
    '首都圏　千葉',
    '中部統括　中京',
    '中部統括　北陸',
    '近畿中四国近畿一',
    '近畿中四国近畿二',
    '近畿中四国　広島',
    '近畿中四国　岡山',
    '近畿中四国　四国',
    '九州統括　福岡',
    '九州統括　沖縄',
    '九州統括　鹿児島',
    '広域ＣＶ',
    '広域ＣＶ（東日本）',
    '広域ＣＶ（西日本）',
    '広域ＣＶ（北海道）',
    '広域ＣＶ（仙台）',
    '広域ＣＶ（盛岡）',
    '広域ＣＶ（新潟）',
    '広域ＣＶ（長野）',
    '広域ＣＶ（東京）',
    '広域ＣＶ（北関東）',
    '広域ＣＶ（神奈川）',
    '広域ＣＶ（千葉）',
    '広域ＣＶ（中京）',
    '広域ＣＶ（北陸）',
    '広域ＣＶ（近畿）',
    '広域ＣＶ（広島）',
    '広域ＣＶ（岡山）',
    '広域ＣＶ（四国）',
    '広域ＣＶ（福岡）',
    '広域ＣＶ（鹿児島）',
    '広域ＣＶ（沖縄）',
    '広域量販',
    '広域量販　東日本',
    '広域量販　東京',
    '広域量販　北関東',
    '広域量販　神奈川',
    '特販課',
    '特販　北海道',
    '特販　仙台',
    '特販　新潟',
    '特販　東京',
    '特販　神奈川',
    '特販　千葉',
    '特販　中京',
    '特販　近畿',
    '特販　広島',
    '特販　岡山',
    '特販　福岡',
    '輸出営業部',
    '本部支店（菓計）',
    '特殊販売',
];